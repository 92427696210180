import '../DetailCampaign.less';
import React from 'react';
import { Col, Row, Typography, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CAMPAIGNS_PATH } from '@constants/routes';
import CaretRight from '@icons/CaretRight';
const { Title, Text } = Typography;

interface Props {
  text: string;
}
const TitleDetailCampaign: React.FunctionComponent<Props> = ({ text }) => {
  const { t } = useTranslation('retailScreen');
  const navigate = useNavigate();
  return (
    <Row
      style={{
        paddingTop: '30px'
      }}
      justify="center"
      className="retail-title-position"
    >
      <Col span={20} style={{ maxWidth: '1920px' }} id="title-detail-campaign">
        <Space size="middle">
          <Title
            level={3}
            className="details__title"
            onClick={() => {
              navigate(CAMPAIGNS_PATH);
            }}
          >
            {`${t('allCampaigns')}`}
          </Title>
          <CaretRight />
          <Text className="details__text">{text}</Text>
        </Space>
      </Col>
    </Row>
  );
};

export default TitleDetailCampaign;

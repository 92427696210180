import './Notification.less';
import { Modal, Space, Typography } from 'antd';
import NotiInfo from '@icons/NotiInfo';
import NotiError from '@icons/NotiError';
import NotiSuccess from '@icons/NotiSuccess';
import NotiWarning from '@icons/NotiWarning';
import { notification } from 'antd';
import WarningCircle from '@icons/WarningCircle';

const { Text } = Typography;
type NotiConfig = {
  duration?: number;
  top?: number;
  placement?: 'topRight' | 'topLeft' | 'bottomRight' | 'bottomLeft';
  message?: string;
  description?: string;
  type: 'success' | 'error' | 'warning' | 'info';
};
export const notiConfig = ({
  duration = 3,
  top = 20,
  placement = 'topRight',
  message,
  description,
  type
}: NotiConfig) => {
  notification.config({
    placement: placement,
    top: top,
    duration: duration
  });
  let icon: any = <></>;
  let color = '';
  if (type === 'success') {
    icon = <NotiSuccess />;
    color = '#0F9918';
  } else if (type === 'info') {
    icon = <NotiInfo />;
    color = '#0B63E5';
  } else if (type === 'error') {
    icon = <NotiError />;
    color = '#E54545';
  } else if (type === 'warning') {
    icon = <NotiWarning />;
    color = '#FF8800';
  }
  notification.open({
    message: <Text className="noti__title">{message}</Text>,
    description: <Text className="noti__des">{description}</Text>,
    type: type,
    className: 'custom-notification',
    icon: icon,
    style: {
      borderLeft: `4px solid ${color}`
    }
  });
};
type NotiProps = {
  type: 'success' | 'error' | 'warning' | 'info';
  okText?: string;
  cancelText?: string;
  title?: string;
  onOk?: () => void;
  onCancel?: () => void;
  content?: string;
};
export const modalNoti = ({
  type,
  okText,
  cancelText,
  title,
  onOk,
  content,
  onCancel
}: NotiProps) => {
  const icons = new Map([
    ['success', <NotiSuccess size={48} />],
    ['error', <WarningCircle />],
    ['warning', <NotiWarning size={48} />]
  ]);

  Modal.confirm({
    title: (
      <Space
        className="noti__confirm__wrapper"
        style={{
          display: 'flex',
          alignContent: 'center'
        }}
      >
        {icons.get(type)}
        <Text className="noti__title__confirm">{title}</Text>
      </Space>
    ),
    icon: <></>,
    content: <Text className="noti__text__content__confirm">{content}</Text>,
    onOk: onOk,
    onCancel: onCancel,
    okText: okText,
    okType: 'danger',
    cancelText: cancelText,
    className: `noti__confirm ${
      type === 'success' ? 'success-custom-noti' : type === 'warning' ? 'warning-custom-noti' : ''
    }`
  });
};


// modalNoti({
//   type: 'success',
//   okText: 'Continue',
//   title: 'Saved',
//   content: 'Changes saved successfully.'
// });

import { FC, ReactElement } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { LOGIN_PATH } from '@constants/routes';
import { getAuthUser } from '@utils/index';

interface Props {
  children?: ReactElement;
}

const AuthRoute: FC<Props> = () => {
  const user = getAuthUser();
  if (!user?.token) {
    return <Navigate to={LOGIN_PATH} />;
  }
  return <Outlet />;
};

export default AuthRoute;

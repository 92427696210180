import { useAppDispatch } from '@store/index';
import { actionAuthLazadaPlatform } from '@store/lazada/action';
import { setLocalItem } from '@utils/index';
import { Spin } from 'antd';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const LazadaCallback = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get('code');
  useEffect(() => {
    if (code) {
      dispatch(actionAuthLazadaPlatform(code))
        .then(data => {
          setLocalItem('authLazada', {
            account: data.account
          });
          window.close();
        })
        .catch(err => {
          console.log('check err::', err);
        });
    }
  }, []);

  return <Spin spinning={true} />;
};

export default LazadaCallback;

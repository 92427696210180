import './DetailCampaign.less';
import {
  Row,
  Col,
  Input,
  Space,
  DatePicker,
  Button,
  Form,
  InputNumber,
  Typography
} from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TitleDetailCampaign from './TitleDetailCampaign/TitleDetailCampaign';
import { actionAddCampaign } from '@store/retail/action';
import { useAppDispatch } from '@store/index';
import { useNavigate } from 'react-router-dom';
import { CAMPAIGNS_PATH, EDIT_CAMPAIGN_PATH } from '@constants/routes';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import { modalNoti } from '@utils/Notification';
import Minus from '@icons/Minus';
import { convertTimestamp, parseDate } from '@utils/index';
const { Text } = Typography;
const validateMessages = {
  required: '${label} is required!'
};
const NewCampaign = () => {
  const { t } = useTranslation('retailScreen');
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [widthImage, setWidthImage] = useState<number | null>(0);
  const [heightImage, setHeightImage] = useState<number | null>(0);
  const [maxProducts, setMaxProducts] = useState<number | null>(0);
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [loadingSave, setLoadingSave] = useState(false);
  const [disableSave, setDisableSave] = useState(false);
  const validateRequiredFieldWidth = () => {
    if (!widthImage) {
      return Promise.reject(new Error('Width is required!'));
    }
    if(Number(widthImage) > 9999){
      return Promise.reject(new Error('Cannot be more than 9999'));
    }
    if(Number(widthImage) < 1){
      return Promise.reject(new Error('Cannot be less than 1'));
    }
    return Promise.resolve();
  };
  
  const validateRequiredFieldHeight = () => {
    if (!heightImage) {
      return Promise.reject(new Error('Height is required!'));
    }
    if(Number(heightImage) > 9999){
      return Promise.reject(new Error('Cannot be more than 9999'));
    }
    if(Number(heightImage) < 1){
      return Promise.reject(new Error('Cannot be less than 1'));
    }
    return Promise.resolve();
  };

  const validateFieldMaxProducts = (e: any) => {
    if(!maxProducts){
      return Promise.reject(new Error('Max products are required!'));
    }
    if(Number(maxProducts) > 99){
      return Promise.reject(new Error('Cannot be more than 99'));
    }
    if(Number(maxProducts) < 1){
      return Promise.reject(new Error('Cannot be less than 1'));
    }
    return Promise.resolve();
  }

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    const today = parseDate(convertTimestamp(Date.now()));
    const startDateCheck = parseDate(convertTimestamp(startDate));

    if(today && startDateCheck){
      if(today >= startDateCheck){
        return current && current < dayjs(Date.now()).endOf('day');
      }
    }
    // Can not select days before today and today
    return current && current < startDate.endOf('day');
  };

  const disableStartDate: RangePickerProps['disabledDate'] = (current) => {
    // Can not select days before today and today
    if(endDate){
      const endDateDisable = endDate?.subtract(1, 'day');
      return current && current > endDateDisable.endOf('day');
    }
    return false
  };
  
  const handleSaveCampaign =async (values: any) => {
    setLoadingSave(true);
    setDisableSave(true);
    const startDate = values?.startDate.startOf('day');
    const endDate = values?.endDate.startOf('day');
    const body = {
      name: values?.name,
      advertiser: values?.advertiser,
      brand: values?.brand,
      imageSizes: `${widthImage}x${heightImage}`,
      startDate:startDate.valueOf(),
      endDate: endDate.valueOf(),
      maxProducts: maxProducts
    }
    try {
      const campaignId = await dispatch(actionAddCampaign(body))
      modalNoti({
        type: 'success',
        okText: 'Continue',
        title: 'Success',
        content: 'Campaign added successfully.',
        onOk: () => {
          navigate(`${EDIT_CAMPAIGN_PATH}/${campaignId}`)
        }
      })
    } catch {
    
      modalNoti({
        type: 'warning',
        okText: 'Try Again',
        title: 'Failed',
        content: 'Please try again.',
        onOk:() => {
          setLoadingSave(false);
      setDisableSave(false);
        }
      });
    }
  };

  return (
    <>
      <TitleDetailCampaign text={`New campaign`} />
      <Row
        justify="center"
        style={{
          marginTop: '10px',
          paddingBottom:'30px',
          maxWidth:'1920px'
        }}  
      >
        <Col span={20}>
          <Form
            name="campaign"
            layout="vertical"
            onFinish={handleSaveCampaign}
            validateMessages={validateMessages}
            form={form}
          >
            <Row>
              <Form.Item
                className="no-asterisk"
                name="name"
                label={t('table.campaignName')}
                rules={[{ required: true }]}
                
              >
                <Input placeholder={t('form.placeholderCampaignName')} type="text" className="custom-input details__search" />
              </Form.Item>
            </Row>
            <Row>
              <Form.Item
                className="no-asterisk"
                name="advertiser"
                label={t('table.advertiser')}
                rules={[{ required: true }]}
              >
                <Input placeholder={t('form.placeholderAdvertiser')} type="text" className="custom-input details__search" />
              </Form.Item>
            </Row>
            <Row>
              <Form.Item
                className="no-asterisk"
                name="brand"
                label={t('table.brand')}
                rules={[{ required: true }]}
              >
                <Input placeholder={t('form.placeholderBrand')} type="text" className="custom-input details__search" />
              </Form.Item>
            </Row>
            <Row>
              <Space size="middle" split={<Minus className='details__icon__split'/>}>
                <Form.Item
                  className="no-asterisk"
                  name="startDate"
                  label={t('form.dates')}
                  rules={[{ required: true }]}
                >
                  <DatePicker
                    className="input-field-custom details__date"
                    placeholder={t('table.startDate')}
                    format="MMM D, YYYY"
                    onChange={(e) => {
                      setStartDate(dayjs(e?.format('MMM D, YYYY')))
                    }}
                    disabledDate={disableStartDate}
                  />
                </Form.Item>

                <Form.Item
                  className="no-asterisk"
                  name="endDate"
                  label={' '}
                  rules={[{ required: true }]}
                >
                  <DatePicker
                    className="input-field-custom details__date"
                    placeholder={t('table.endDate')}
                    format="MMM D, YYYY"
                    disabledDate={disabledDate}
                    onChange={(e) => {
                      setEndDate(dayjs(e?.format('MMM D, YYYY')))
                    }}
                    disabled={startDate ? false : true}
                  />
                </Form.Item>
              </Space>
            </Row>
            <Row>
            <Text className='details__text__img__size'>{t('form.imageSize')}</Text>
          </Row>
          <Row style={{
            marginTop: '10px'
          }}>
            
          <div style={{ display: 'flex', flexDirection: 'row', minWidth: '192px' }}>
              <Text className='details__text__size'> {t('form.width')}</Text>
              <Form.Item
              
              className="no-asterisk"
              name="width"
              rules={[{ validator: validateRequiredFieldWidth }]}
            >
              
              <InputNumber
                className="input-field-custom details__input__number"
                placeholder={t('form.placeholderWidth')}
                onChange={value => {
                  setWidthImage(Number(value));
                }}
                style={{
                  minWidth:'126px'
                }}
              />
            </Form.Item>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Text className='details__text__size' style={{
              marginLeft:'10px'
            }}> {t('form.height')} </Text>
         
           
            <Form.Item
              className="no-asterisk"
              name="height"
              rules={[{ validator: validateRequiredFieldHeight }]}
            >
              <InputNumber
                className='input-field-custom'
                placeholder={t('form.placeholderHeight')}
                onChange={value => {
                  setHeightImage(Number(value));
                }}
                style={{
                  minWidth:'134px'
                }}
              />
            </Form.Item>
            </div>
          </Row>
            <Row>
              <Form.Item 
                name="maxProducts" 
                label={t('form.maxProducts')}
                rules={[{ validator: validateFieldMaxProducts }]}
                >
                <InputNumber
                  className='input-field-custom'
                  style={{
                    width: '183%'
                  }}
                  placeholder={t('form.placeholderMaxProducts')}
                  onChange={value => {
                    setMaxProducts(Number(value));
                  }}
                />
              </Form.Item>
            </Row>{' '}
            
            <Row>
              <Space size='large'>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="details__btn__submit"
                  size="middle"
                  loading={loadingSave}
                  disabled={disableSave}
                >
                  {t('form.createCampaign')}
                </Button>
                <Button
                  type="primary"
                  ghost
                  className="details__btn__submit"
                  onClick={() => {
                    navigate(CAMPAIGNS_PATH)  
                  }}
                >
                  {t('form.cancel')}
                </Button>
              </Space>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default NewCampaign;

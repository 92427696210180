
import { BodyFirstTimeLogin, BodyLogin, BodyLogout } from 'src/types/api';
import { baseApi } from './baseClient';

const LOGIN_API = '/auth/login';
const FIRST_TIME_LOGIN_API = '/auth/new-password'
const LOGOUT_API = '/auth/revoke-token';
const SERVER_LIST = '/admin/servers';
export const loginAPI = async (body: BodyLogin) => {
  return await baseApi('').post(LOGIN_API, body)
}

export const firstTimeLoginAPI = async (body: BodyFirstTimeLogin) => {
  return await baseApi('').post(FIRST_TIME_LOGIN_API, body)
}

export const logoutAPI = async (body: BodyLogout) => {
  return await baseApi('').post(LOGOUT_API, body)
}

export const serverList = async () => {
  return await baseApi('').get(SERVER_LIST)
}

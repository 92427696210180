import {  CaretDownOutlined, UserOutlined } from '@ant-design/icons';
import { useAppDispatch } from '@store/index';
import './Layout.less';
import { Layout, Space, Col, Row, Typography, Dropdown, MenuProps, Avatar } from 'antd';
import { getAuthUser } from '@utils/index';
import { notiConfig } from '@utils/Notification';
import { actionLogout } from '@store/auth/action';
import { useNavigate } from 'react-router-dom';
import { CAMPAIGNS_PATH, LOGIN_PATH, PICK_SERVER_PATH } from '@constants/routes';
import LogoHome from '@icons/LogoHome';

const { Text } = Typography;
const allowPickServer = ['admin', 'developer'];
const Header = () => {

  const user = getAuthUser();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleLogout = async () => {
    try {
      await dispatch(actionLogout())
      notiConfig({
        type:'success',
        message: 'See you again!'
      })
      navigate(LOGIN_PATH)
    } catch (error) {
      notiConfig({
        type: 'warning',
        message: 'Something went wrong, pls refresh the page!'
      })
    }
  }
 
  const items: MenuProps['items'] = [
   
    {
      label: <Text className='layout__text__dropdown'>Logout</Text>,
      key: '3',
      onClick: () => {
        handleLogout()
      }
    },
  ];

  const itemsAdmin: MenuProps['items'] = [
    {
      label: <Text className='layout__text__dropdown' >Select Client</Text>,
      key: '1',
      onClick: () => {
        navigate(PICK_SERVER_PATH)
      }
    },
 
    {
      label: <Text className='layout__text__dropdown'>Logout</Text>,
      key: '3',
      onClick: () => {
        handleLogout()
      }
    },
  ];

  return (
    <Layout.Header className="layout__header" id="header-position">
      <Row style={{
        justifyContent:'space-between'
      }}>
        <Col span={6}>
          <LogoHome onClick={() => {navigate(CAMPAIGNS_PATH)}} size={200} height={85} className="layout__logo" />
        </Col>
       
        <Col span={18} style={{
          paddingTop:'7px'
        }}>
          <Space align="center" size="large" className="layout__header__info">
            <Text
              style={{
                color: 'rgba(11, 99, 229, 1)'
              }}
            >
              {user?.email}
            </Text>
            <Avatar size="large" icon={<UserOutlined />} />
            <Dropdown menu={{ items: allowPickServer.includes(user?.role[0]) ? itemsAdmin : items }} trigger={['click']} overlayClassName="layout__dropdown"  placement="bottomRight" arrow={{ pointAtCenter: false }}>
              <CaretDownOutlined />
            </Dropdown>
            
          </Space>
        </Col>
      </Row>
    </Layout.Header>
  );
};

export default Header;

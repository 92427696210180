import { AxiosError } from "axios";
import { AppThunk } from "..";
import { setFirstTimeSignIn } from "./slice";
import { loginAPI, firstTimeLoginAPI, serverList } from '@api/auth';
import jwtDecode from 'jwt-decode';
import { sortRoles } from "@utils/index";
import { notiConfig } from '@utils/Notification'

type LoginResponseType = {
  type: string;
  data: any
}
const rolesPickServer = ['admin', 'developer']
export const actionFirstTimeSignIn = (
  email: string,
  session: string,
  newPassword: string
): AppThunk<Promise<void>> => {
  return async () => {
    try {   
      const { data } = await firstTimeLoginAPI({email, session, newPassword});
      const decode: any = jwtDecode(data?.data?.token);
      const roles = sortRoles(decode["cognito:groups"])

      
      if(rolesPickServer.includes(roles[0])){
        localStorage.setItem('authUser', JSON.stringify({
          ...data.data,
          email: email,
          role: [roles[0]],
      
        }))
      }else{
        if(data.data.api){
          localStorage.setItem('authUser', JSON.stringify({
            ...data.data,
            email: email,
            role: [roles[0]],
        
          }))
        }else{
          notiConfig({
            type: 'error',
            message:'NOT SUPPORT',
            description: `Your account has not been authorized to use.`
          })
        }
      }
    } catch (error) {
      const err = error as AxiosError
      throw err.response?.data;
    }
  };
};

export const actionLogin = (
  email: string,
  password: string
): AppThunk<Promise<LoginResponseType>> => {
  return async (dispatch) => {
    try {   
      const {data} = await loginAPI({email, password});
      if(data.data?.type === 'FIRST_SIGN_IN'){
        await dispatch(setFirstTimeSignIn({
          email: email,
          session: data?.data?.session
        }))
        return {
          type: 'FIRST_TIME_LOGIN',
          data:{
            session: data?.data?.session
          }
        }
      }else{
        const decode: any = jwtDecode(data?.data?.token);
        const roles = sortRoles(decode["cognito:groups"])

        if(rolesPickServer.includes(roles[0])){
          localStorage.setItem('authUser', JSON.stringify({
            ...data.data,
            email: email,
            role: [roles[0]],
        
          }))
        }else{
          if(data.data.api){
            localStorage.setItem('authUser', JSON.stringify({
              ...data.data,
              email: email,
              role: [roles[0]],
            }))
          }else{
            notiConfig({
              type: 'error',
              message:'NOT SUPPORT',
              description: `Your account has not been authorized to use.`
            })
          }
        }
        return {
          type:'LOGIN_SUCCESSFULLY',
          data: null
        }
      }
    } catch (error) {
      const err = error as AxiosError
      throw err?.response?.data;
    }
  };
};

export const actionLogout = (
  
): AppThunk<Promise<void>> => {
  return async () => {
    try {   
      localStorage.setItem('authUser', JSON.stringify(null))
    } catch (error) {
      const err = error as AxiosError
      throw err?.response?.data;
    }
  };
};

export const actionGetServerList = (
): AppThunk<Promise<any>> => {
  return async () => {
    try {   
      const {data} = await serverList();
      return data.data
    } catch (error) {
      const err = error as AxiosError
      throw err?.response?.data;
    }
  };
};


import { baseApi } from './baseClient';
import { getAuthUser } from '@utils/index';

const URL_AUTH_LAZADA_PLATFORM = '/auth/lazada/token/create';
const URL_LAZADA_GET_PRODUCTS='/lazada/products'
const URL_INFO_LAZADA_AUTHENTICATION='/user/lazada/info'
const URL_LOGOUT_LAZADA = '/user/lazada/logout';
const URL_AUTO_UPDATE_DATA_FROM_LAZADA = '/auto/scrape/lazada'
export const authLazada = async (code: string) => {
  return await baseApi(getAuthUser().api).get(`${URL_AUTH_LAZADA_PLATFORM}?code=${code}`)
}

export const getProducts = async (account: string, limit: number, offset: number) => {
  return await baseApi(getAuthUser().api).get(`${URL_LAZADA_GET_PRODUCTS}?account=${account}&limit=${limit}&offset=${offset}`)
}

export const getInfoAuthLazada = async () => {
  return await baseApi(getAuthUser().api).get(`${URL_INFO_LAZADA_AUTHENTICATION}`)
}

export const logoutLazada = async (account: string) => {
  return await baseApi(getAuthUser().api).put(`${URL_LOGOUT_LAZADA}`, {
    account: account
  })
}

export const autoUpdateDataFromLazada = async () => {
  return await baseApi(getAuthUser().api).get(`${URL_AUTO_UPDATE_DATA_FROM_LAZADA}`,{
    timeout: 1200000
  })
}

import React from 'react';

interface Props {
  size?: number;
  height?: number;
  className?: string;
  onClick?: () => void;
}

const Minus = ({ size = 24, height, className, onClick }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={height ? height : size}
    width={(size * 595.3) / 841.9}
    viewBox="0 0 24 24"
    className={className}
    fill="none"
    onClick={onClick}
  >
    <path
      d="M3.75 12H20.25"
      stroke="#838E9E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Minus;

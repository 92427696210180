import React from 'react';

interface Props {
  size?: number;
  height?: number;
  className?: string;
  onClick?: () => void;
}

const NotiError = ({ size = 32, height, className, onClick }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={height ? height : size}
    width={(size * 595.3) / 841.9}
    viewBox="0 0 32 32"
    className={className}
    fill="none"
    onClick={onClick}
  >
    <path
      opacity="0.2"
      d="M20.5563 4H11.4437C11.3123 4 11.1823 4.02587 11.061 4.07612C10.9396 4.12638 10.8294 4.20004 10.7365 4.29289L4.29289 10.7365C4.20004 10.8294 4.12638 10.9396 4.07612 11.061C4.02587 11.1823 4 11.3123 4 11.4437V20.5563C4 20.6877 4.02587 20.8177 4.07612 20.939C4.12638 21.0604 4.20004 21.1706 4.29289 21.2635L10.7365 27.7071C10.8294 27.8 10.9396 27.8736 11.061 27.9239C11.1823 27.9741 11.3123 28 11.4437 28H20.5563C20.6877 28 20.8177 27.9741 20.939 27.9239C21.0604 27.8736 21.1706 27.8 21.2635 27.7071L27.7071 21.2635C27.8 21.1706 27.8736 21.0604 27.9239 20.939C27.9741 20.8177 28 20.6877 28 20.5563V11.4437C28 11.3123 27.9741 11.1823 27.9239 11.061C27.8736 10.9396 27.8 10.8294 27.7071 10.7365L21.2635 4.29289C21.1706 4.20004 21.0604 4.12638 20.939 4.07612C20.8177 4.02587 20.6877 4 20.5563 4Z"
      fill="#E64545"
    />
    <path
      d="M16 10V17"
      stroke="#E64545"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.5563 4H11.4437C11.3123 4 11.1823 4.02587 11.061 4.07612C10.9396 4.12638 10.8294 4.20004 10.7365 4.29289L4.29289 10.7365C4.20004 10.8294 4.12638 10.9396 4.07612 11.061C4.02587 11.1823 4 11.3123 4 11.4437V20.5563C4 20.6877 4.02587 20.8177 4.07612 20.939C4.12638 21.0604 4.20004 21.1706 4.29289 21.2635L10.7365 27.7071C10.8294 27.8 10.9396 27.8736 11.061 27.9239C11.1823 27.9741 11.3123 28 11.4437 28H20.5563C20.6877 28 20.8177 27.9741 20.939 27.9239C21.0604 27.8736 21.1706 27.8 21.2635 27.7071L27.7071 21.2635C27.8 21.1706 27.8736 21.0604 27.9239 20.939C27.9741 20.8177 28 20.6877 28 20.5563V11.4437C28 11.3123 27.9741 11.1823 27.9239 11.061C27.8736 10.9396 27.8 10.8294 27.7071 10.7365L21.2635 4.29289C21.1706 4.20004 21.0604 4.12638 20.939 4.07612C20.8177 4.02587 20.6877 4 20.5563 4Z"
      stroke="#E64545"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M16 23C16.8284 23 17.5 22.3284 17.5 21.5C17.5 20.6716 16.8284 20 16 20C15.1716 20 14.5 20.6716 14.5 21.5C14.5 22.3284 15.1716 23 16 23Z"
      fill="#E64545"
    />
  </svg>
);

export default NotiError;

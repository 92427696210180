import { FC, ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import { CAMPAIGNS_PATH, LOGIN_PATH } from '@constants/routes';
import { getAuthUser } from '@utils/index';

interface Props {
  children?: ReactElement;
}

const RouteNotFound: FC<Props> = () => {
  const user = getAuthUser();
  if (!user?.token || !user?.api) {
    return <Navigate to={LOGIN_PATH} />;
  }
  
  return <Navigate to={CAMPAIGNS_PATH} />
  
};

export default RouteNotFound;

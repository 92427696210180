import React from 'react';

interface Props {
  size?: number;
  height?: number;
  className?: string;
  onClick?: () => void;
}

const WarningCircle = ({ size = 48, height, className, onClick }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={size}
    width={size}
    viewBox="0 0 48 48"
    className={className}
    fill="none"
    onClick={onClick}
  >
    <path
      opacity="0.2"
      d="M24 42C33.9411 42 42 33.9411 42 24C42 14.0589 33.9411 6 24 6C14.0589 6 6 14.0589 6 24C6 33.9411 14.0589 42 24 42Z"
      fill="#E64545"
    />
    <path
      d="M24 42C33.9411 42 42 33.9411 42 24C42 14.0589 33.9411 6 24 6C14.0589 6 6 14.0589 6 24C6 33.9411 14.0589 42 24 42Z"
      stroke="#E64545"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M24 15V25.5"
      stroke="#E64545"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24 34.5C25.2426 34.5 26.25 33.4926 26.25 32.25C26.25 31.0074 25.2426 30 24 30C22.7574 30 21.75 31.0074 21.75 32.25C21.75 33.4926 22.7574 34.5 24 34.5Z"
      fill="#E64545"
    />
  </svg>
);

export default WarningCircle;

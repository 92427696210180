import { Layout } from 'antd';
import { useTranslation } from 'react-i18next';
const { Footer } = Layout;
const FooterLayout = () => {
  const { t } = useTranslation('layout');
  return (
    <>
      <Footer
        style={{
          width: '100%',
          background: 'rgba(2, 20, 46, 1)',
          color: 'rgba(255, 255, 255, 1)',
          textAlign: 'center'
        }}
        id="footer-position"
      >
        {t('contentFooter')}
      </Footer>
    </>
  );
};

export default FooterLayout;

import React from 'react';

interface Props {
  size?: number;
  height?: number;
  className?: string;
  onClick?: () => void;
}

const NotiInfo = ({ size = 32, height = 52, className, onClick }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={height ? height : size}
    width={(size * 595.3) / 841.9}
    viewBox="0 0 32 52"
    className={className}
    fill="none"
    onClick={onClick}
  >
    <path
      opacity="0.2"
      d="M16 48C22.6274 48 28 42.6274 28 36C28 29.3726 22.6274 24 16 24C9.37258 24 4 29.3726 4 36C4 42.6274 9.37258 48 16 48Z"
      fill="#0B63E5"
    />
    <path
      d="M16 48C22.6274 48 28 42.6274 28 36C28 29.3726 22.6274 24 16 24C9.37258 24 4 29.3726 4 36C4 42.6274 9.37258 48 16 48Z"
      stroke="#0B63E5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 35H16V42H17"
      stroke="#0B63E5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 32C16.8284 32 17.5 31.3284 17.5 30.5C17.5 29.6716 16.8284 29 16 29C15.1716 29 14.5 29.6716 14.5 30.5C14.5 31.3284 15.1716 32 16 32Z"
      fill="#0B63E5"
    />
  </svg>
);

export default NotiInfo;

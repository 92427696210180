import React from 'react';

interface Props {
  size?: number;
  height?: number;
  className?: string;
  onClick?: () => void;
}

const NotiWarning = ({ size = 32, height, className, onClick }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={height ? height : size}
    width={(size * 595.3) / 841.9}
    viewBox="0 0 32 32"
    className={className}
    fill="none"
    onClick={onClick}
  >
    <path
      opacity="0.2"
      d="M14.269 4.99809L3.27201 23.9971C3.09605 24.3011 3.00325 24.6461 3.00296 24.9974C3.00267 25.3486 3.0949 25.6938 3.27036 25.9981C3.44582 26.3024 3.69833 26.5551 4.00249 26.7308C4.30664 26.9065 4.65171 26.999 5.00297 26.999H26.997C27.3483 26.999 27.6934 26.9065 27.9975 26.7308C28.3017 26.5551 28.5542 26.3024 28.7296 25.9981C28.9051 25.6938 28.9973 25.3486 28.997 24.9974C28.9967 24.6461 28.9039 24.3011 28.728 23.9971L17.7309 4.99809C17.5553 4.69457 17.3028 4.44258 16.999 4.2674C16.6952 4.09222 16.3507 4 16 4C15.6493 4 15.3048 4.09222 15.0009 4.2674C14.6971 4.44258 14.4447 4.69457 14.269 4.99809Z"
      fill="#FF8800"
    />
    <path
      d="M16 14V18"
      stroke="#FF8800"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.269 4.99809L3.27201 23.9971C3.09605 24.3011 3.00325 24.6461 3.00296 24.9974C3.00267 25.3486 3.0949 25.6938 3.27036 25.9981C3.44582 26.3024 3.69833 26.5551 4.00249 26.7308C4.30664 26.9065 4.65171 26.999 5.00297 26.999H26.997C27.3483 26.999 27.6934 26.9065 27.9975 26.7308C28.3017 26.5551 28.5542 26.3024 28.7296 25.9981C28.9051 25.6938 28.9973 25.3486 28.997 24.9974C28.9967 24.6461 28.9039 24.3011 28.728 23.9971L17.7309 4.99809C17.5553 4.69457 17.3028 4.44258 16.999 4.2674C16.6952 4.09222 16.3507 4 16 4C15.6493 4 15.3048 4.09222 15.0009 4.2674C14.6971 4.44258 14.4447 4.69457 14.269 4.99809V4.99809Z"
      stroke="#FF8800"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 24C16.8284 24 17.5 23.3284 17.5 22.5C17.5 21.6716 16.8284 21 16 21C15.1716 21 14.5 21.6716 14.5 22.5C14.5 23.3284 15.1716 24 16 24Z"
      fill="#FF8800"
    />
  </svg>
);

export default NotiWarning;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = {
  user: null,
  firstTimeSignIn: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setFirstTimeSignIn(state, action: PayloadAction<any>) {
      state.firstTimeSignIn = action.payload;
    },
    setAuthUser(state, action: PayloadAction<any>){
      state.user = action.payload
    }
  },
});

export const { 
  setFirstTimeSignIn,
  setAuthUser
} = authSlice.actions;

export default authSlice.reducer;
import { authLazada, getInfoAuthLazada, getProducts, logoutLazada } from "@api/lazada";
import { AppThunk } from "..";
import { AxiosError } from "axios";

export const actionAuthLazadaPlatform = (
  code: string
): AppThunk<Promise<any>> => {
  return async () => {
    try {   
      const {data} = await authLazada(code);
      return data.data
    } catch (error) {
      const err = error as AxiosError
      throw err.response?.data;
    }
  };
};

export const actionGetProducts = (
  account: string,
  limit: number,
  offset: number
): AppThunk<Promise<any>> => {
  return async () => {
    try {   
      const {data} = await getProducts(account, limit, offset);
      return data.data
    } catch (error) {
      const err = error as AxiosError
      throw err.response?.data;
    }
  };
};

export const actionGetInfoLazadaAuth = (
): AppThunk<Promise<any>> => {
  return async () => {
    try {   
      const {data} = await getInfoAuthLazada();
      return data.data
    } catch (error) {
      const err = error as AxiosError
      throw err.response?.data;
    }
  };
};

export const actionLogoutLazada = (
  account: string
  ): AppThunk<Promise<any>> => {
    return async () => {
      try {   
        const {data} = await logoutLazada(account);
        return data.data
      } catch (error) {
        const err = error as AxiosError
        throw err.response?.data;
      }
    };
  };

import { CAMPAIGNS_PATH } from "@constants/routes";
import { getAuthUser } from "@utils/index";
import { FC, ReactElement } from "react";
import { Navigate, Outlet } from "react-router-dom";

interface Props {
  children?: ReactElement;
}

const UnauthRoute: FC<Props> = () => {
  const user = getAuthUser();
  if(user?.api || user?.token){
    return <Navigate to={CAMPAIGNS_PATH}/>
  }
  return  <Outlet />
  
};

export default UnauthRoute;
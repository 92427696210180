import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = {
  campaign: null,
  productEdit: null,
  feedForm: {
    urlsFeed: '',
    ctaText: '',
  },
};

export const retailSlice = createSlice({
  name: "retail",
  initialState,
  reducers: {
    setDetailCampaign(state, action: PayloadAction<any>) {
      state.campaign = action.payload;
    },
    setProductEdit(state, action: PayloadAction<any>){
      state.productEdit = action.payload;
    },
    setFeedForm(state, action: PayloadAction<any>){
      state.feedForm = action.payload;
    }
  },
});

export const { 
  setDetailCampaign,
  setProductEdit,
  setFeedForm
} = retailSlice.actions;

export default retailSlice.reducer;
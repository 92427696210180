import React, { Suspense } from 'react';
import { Routes as ReactRoutes, Route } from 'react-router-dom';
import { RouteConfig } from 'src/interfaces/routes';
import UnauthRoute from './UnauthRoute';
import Layout from '@layout/Layout';
import AuthLayout from '@layout/auth/AuthLayout';
import AuthRoute from './AuthRoute';
import NewCampaign from '@screens/detailCampaign/NewCampaign';
import AdminRoute from './AdminRoute';
import RouteNotFound from './RouteNotFound';
import LazadaCallback from '@screens/detailCampaign/lazadaCallback/LazadaCallback';

const Retail = React.lazy(() => import('@screens/retail/Retail'));
const LoginForm = React.lazy(() => import('@screens/auth/Login'));
const ChangePassword = React.lazy(() => import('@screens/auth/ChangePassword'));
const EditCampaign = React.lazy(() => import('@screens/detailCampaign/EditCampaigns'));
const PickServer = React.lazy(() => import('@screens/servers/PickServer'));
const retailRoutes: RouteConfig = {
  path: '/retail',
  element: <Layout />,
  guard: <AuthRoute />,
  children: [
    {
      path: 'all-campaigns',
      element: <Retail />
    },
    {
      path: 'new-campaign',
      element: <NewCampaign />,
      guard: <AdminRoute />
    },
    {
      path: 'servers',
      element: <PickServer />,
      guard: <AdminRoute />
    },
    {
      path: 'edit-campaign/:id',
      element: <EditCampaign />
    }
  ]
};

const lazadaRoutes: RouteConfig = {
  path: '/lazada',
  element: <AuthLayout />,
  guard: <AuthRoute />,
  children: [
    {
      path: 'callback',
      element: <LazadaCallback />
    }
  ]
};

const authRoutes: RouteConfig = {
  path: '/auth',
  element: <AuthLayout />,
  guard: <UnauthRoute />,
  children: [
    {
      path: 'login',
      element: <LoginForm />
    },
    {
      path: 'new-password/:session',
      element: <ChangePassword />
    }
  ]
};

const notfoundRoute: RouteConfig = {
  path: '*',
  element: <></>,
  guard: <RouteNotFound />,
  children: [
    {
      path: '*',
      element: <></>
    }
  ]
};
const routes = [retailRoutes, lazadaRoutes, authRoutes, notfoundRoute];

const Routes = () => {
  return (
    <Suspense fallback={<div />}>
      <ReactRoutes>
        {routes.map(route => (
          <Route key={route.path} element={route.guard}>
            <Route path={route.path} element={route.element}>
              {route.children
                ? route.children.map(({ element, path, guard }) => (
                    <Route key={path} element={guard}>
                      <Route path={path} element={element} />
                    </Route>
                  ))
                : null}
            </Route>
          </Route>
        ))}
      </ReactRoutes>
    </Suspense>
  );
};
export default Routes;

import { Layout } from 'antd';
import './AuthLayout.less';
import { Outlet } from 'react-router-dom';
import FooterLayout from '@layout/Footer';
import { Content } from 'antd/es/layout/layout';
const AuthLayout = () => {
  return (
    <Layout className="authLayout__layout">
      <Content className="authLayout__content">
        <Outlet />
      </Content>
      <FooterLayout />
    </Layout>
  );
};

export default AuthLayout;

import { Outlet } from 'react-router-dom';
import { Layout } from 'antd';
import Header from './Header';
import FooterLayout from './Footer';
import { useEffect, useState } from 'react';
const { Content } = Layout;

const RetailLayout = () => {
  const [headerHeight, setHeaderHeight] = useState(0);
  useEffect(() => {
    const heightHeder = document.getElementById('header-position')?.getBoundingClientRect().height;
    const footerHeder = document.getElementById('footer-position')?.getBoundingClientRect().height;
    if(heightHeder){
      setHeaderHeight(heightHeder + Number(footerHeder));
    }
  }, [])

  return (
    <Layout
      style={{
        background: '#fff'
      }}
    >
      <Header />
      <Content style={{
        backgroundColor:'rgba(245, 246, 247, 1)',
        minHeight: `calc(100vh - ${headerHeight}px)`,
      }}>
        <Outlet />
      </Content>
      <FooterLayout />
    </Layout>
  );
};

export default RetailLayout;
